import React, { Fragment, useState } from "react";
import { Dialog, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline';
import { PlusIcon, PhotoIcon } from '@heroicons/react/24/outline';

import Toggle from "./Toggle";
import { classNames } from "../lib";
import LogoImage from "./LogoImage";
import SEOMetadata from "./SEOMetadata";
// import Footer from "./Footer";

const navigation = [
  { name: 'Make', href: '#', icon: PlusIcon },
  { name: 'Showcase', href: '#', icon: PhotoIcon }
]



const Layout = ({ children, toggleTheme, theme, screen, setScreen }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  return (
    <React.Fragment>
    <SEOMetadata/>
      <main className="flex flex-col min-h-screen dark:bg-iron-900">
        {/* <Header/> */}
        <div className="flex-1">

          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white dark:bg-iron-900">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                      <div className="flex flex-shrink-0 items-center justify-center px-4">
                        <LogoImage classes='h-12 w-auto'/>
                        
                      </div>
                      <nav className="mt-12 space-y-4 px-2">
                        {navigation.map((item) => (
                          <a
                            key={item.name}
                            // href={item.href}
                            onClick={() => {
                              setScreen(item.name)
                              setSidebarOpen(false)
                              }}
                            className={classNames(
                              item.name === screen
                                ? 'bg-gray-100 text-iron-900 dark:bg-iron-800 dark:text-iron-100'
                                : 'text-iron-600 hover:bg-gray-50 hover:text-iron-900 dark:hover:bg-iron-800 dark:text-iron-400',
                              'group flex items-center rounded-md px-2 py-2 text-base font-medium'
                            )}
                          >
                            <item.icon
                              className={classNames(
                                item.name === screen ? 'text-iron-500' : 'text-iron-400 group-hover:text-iron-500',
                                'mr-4 h-6 w-6 flex-shrink-0'
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </nav>
                    </div>
                    <div className="flex flex-shrink-0 justify-between border-t border-gray-200 p-4">
                      <a href="" className="group block flex-shrink-0">
                        <div className="flex items-center">
                          <div>
                            <img
                              className="inline-block h-6 w-6 rounded-full"
                              src="./images/flag.jpeg"
                              alt=""
                            />
                          </div>
                          <div className="ml-3">
                            <p className="text-sm font-base text-iron-800 group-hover:text-iron-600 dark:text-iron-200">Made in Bharat</p>
                            {/* <p className="text-sm font-medium text-iron-500 group-hover:text-iron-700">View profile</p> */}
                          </div>
                        </div>
                      </a>
                      <div className="flex items-center justify-center">
                          <Toggle statusChange={toggleTheme} status={theme === 'dark'? true: false} style='theme'/>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
                <div className="w-14 flex-shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white dark:bg-iron-900 dark:border-iron-800">
              <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
                <div className="flex flex-shrink-0 items-center px-4">
                  <LogoImage classes='h-10 w-auto'/>
                </div>
                <nav className="mt-16 flex-1 space-y-1 bg-white px-2 dark:bg-iron-900">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      // href={item.href}
                      onClick={() => setScreen(item.name)}
                      className={classNames(
                        item.name === screen ? 'bg-gray-100 text-iron-900 dark:bg-iron-800 dark:text-iron-100' 
                        :'text-iron-600 hover:bg-gray-50 hover:text-iron-900 dark:hover:bg-iron-800 dark:hover:text-iron-400',
                        'group flex items-center rounded-md px-2 py-4 text-sm font-medium'
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.name === screen ? 'text-iron-900 dark:text-iron-100' 
                          :'text-iron-600 group-hover:text-iron-900 dark:text-iron-600 dark:group-hover:text-iron-400',
                          'mr-3 h-6 w-6 flex-shrink-0'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  ))}
                </nav>
              </div>
              <div className="flex flex-shrink-0 justify-between border-t border-gray-200 p-4 dark:border-iron-800">
                <a href="" className="group block flex-shrink-0">
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block h-6 w-6 rounded-full"
                        src="./images/flag.jpeg"
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-base text-iron-800 group-hover:text-iron-600 dark:text-iron-200">Made in Bharat</p>
                      {/* <p className="text-xs font-medium text-iron-500 group-hover:text-iron-700">View profile</p> */}
                    </div>
                  </div>
                </a>
                <div className="flex items-center justify-center">
                          <Toggle statusChange={toggleTheme} status={theme === 'dark'? true: false} style='theme'/>
                  </div>
              </div>
            </div>
          </div>
          

          <div className="flex flex-1 flex-col lg:pl-64 ">
            <div className="flex justify-between items-center top-0 z-10  bg-white px-1 py-1 sm:px-3 sm:py-3 lg:hidden border-b border-iron-300 dark:border-iron-800 dark:bg-iron-900">
              <button
                type="button"
                className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-iron-500 hover:text-iron-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                
              </button>
              <LogoImage classes='h-10 w-auto'/>
              <a
                href="https://www.bookmykiln.com/waitlist"
              >
                <button
                  type="button"
                  className="rounded py-1 px-1 text-xs border border-iron-800 font-base text-iron-400 shadow-sm hover:bg-white/20 "
                >
                  Waitlist
                </button> 
              </a>
              
            </div>

            {children}
          </div>

        </div>
        {/* <Footer /> */}
      </main>
    </React.Fragment>
  );
};

export default Layout;

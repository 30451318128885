export const addPrompt = async (artist_id=1, inputs={ 
    "form": "ancient indian pottery",
    "color": "red",
    "surface": "elephant print"
  }) => {
    const url = process.env.REACT_APP_HASURA_ENDPOINT;
    const query = `
    mutation AddPrompt($inputs: jsonb={}, $artist_id: Int!) {
        insert_prompt_one(object: {inputs: $inputs, artist_id: $artist_id}) {
          image_url
        }
      }
    `;


    const variables = {
        artist_id,
        inputs
    };
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-hasura-admin-secret': process.env.REACT_APP_HASURA_ADMIN_SECRET,
        'x-hasura-role': 'artist'
      },
      body: JSON.stringify({
        query: query,
        variables: variables
      })
    };
  
    try {
      const response = await fetch(url, options);
      const result = await response.json();
      console.log('inside try block')
      return !!result.data.insert_prompt_one;
    } catch (error) {
        return false;
    }
  };
  

export const addArtist = async (whatsapp_number='919999999993') => {
    const url = process.env.REACT_APP_HASURA_ENDPOINT;
  
    const query = `
      mutation AddArtist($whatsapp_number: String!) {
        insert_artist(objects: {whatsapp_number: $whatsapp_number}) {
            affected_rows
            returning {
                id
              }
        }
      }
    `;
  
    const variables = {
        whatsapp_number,
    };
  
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-hasura-admin-secret': process.env.REACT_APP_HASURA_ADMIN_SECRET,
        'x-hasura-role': 'artist'
      },
      body: JSON.stringify({
        query,
        variables
      })
    };
  
    const response = await fetch(url, options);
    const data = await response.json();
  
    return {
        added: data.data.insert_artist.affected_rows > 0,
        artist_id: data.data.insert_artist.returning[0].id 
    }
    // return data;
  };
  
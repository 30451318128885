// Create a button component
import React from 'react';
import { SparklesIcon } from '@heroicons/react/24/solid';

const Button = ({ text='Submit', onClick, disabled=false, type='button', isLoading }) => {
  return (
    <button
        onClick={onClick}
        disabled={disabled}
        type={type}
        className="w-full inline-flex justify-center items-center gap-x-2 rounded-md bg-blue-600 py-5 px-4 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600
        disabled:cursor-not-allowed disabled:bg-iron-600 disabled:text-gray-400 disabled:ring-gray-200"
        >
        <p>{isLoading ? "Submitting" : text}</p>
        {isLoading? <Spinny/> :
          <SparklesIcon className="-mr-0.5 h-4 w-4 hover:animate-ping" aria-hidden="true" />}

    </button>


  );
}       

const Spinny = () => {
  return (
    <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v1a7 7 0 00-7 7H4z"></path>
    </svg>
  )
}



export default Button;
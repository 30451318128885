const LoadingSkeleton = () => {
    return (
      <div class="bg-iron-200 shadow rounded-lg p-2 max-w-sm w-full mx-auto dark:bg-iron-800">
      <div class="animate-pulse flex flex-col space-x-1 space-y-2">
        <div class="rounded-lg h-24 w-full bg-iron-100 dark:bg-iron-900"></div>

        <div class="flex-1 space-y-2 py-1">
          <div class="h-2  rounded bg-iron-100 dark:bg-iron-900"></div>
          <div class="space-y-3">
            <div class="grid grid-cols-3 gap-4">
              <div class="h-2  rounded col-span-2 bg-iron-100 dark:bg-iron-900"></div>
              <div class="h-2  rounded col-span-1 bg-iron-100 dark:bg-iron-900"></div>
            </div>
            <div class="h-2  rounded bg-iron-100 dark:bg-iron-900"></div>
          </div>
        </div>
      </div>
    </div>
    )
}

export default LoadingSkeleton;
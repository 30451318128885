import { classNames } from "../lib";
import PopoverIcon from "./PopoverIcon";

export default function Input({ label, type="text", about, placeholder="Enter here", popoverText, inputs, setInputs, maxInputLength=25 }) {
    return (
      <div>
        <div className="flex justify-between">
          <label htmlFor={type} className="block text-lg font leading-6 text-iron-900 dark:text-iron-50">
            {label}
          </label>
          <span className="text-sm leading-6 text-iron-500" id="email-optional">
          <PopoverIcon text={popoverText}/>
          </span>
        </div>
        <div className="mt-2">
          <input
            type={type}
            name={type}
            id={type}
            value={inputs[about]}
            onChange={(e) => setInputs({...inputs, [about]: e.target.value})}
            className={classNames(
              (inputs[about].length > maxInputLength) 
              ? "border-red-600 ring-red-600 dark:ring-red-500" 
              :  " focus:ring-iron-300 dark:focus:ring-iron-400", 
               "block w-full rounded-md border-0 p-3 bg-iron-100 text-iron-900 shadow-sm ring-1 ring-inset ring-iron-100 placeholder:text-iron-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 dark:bg-iron-800 dark:text-white dark:placeholder:text-iron-500 dark:ring-iron-800"
               )}
            
            placeholder={placeholder}
            aria-describedby={type}
          />
        </div>
        {
          inputs[about].length > maxInputLength &&
          <p className="mt-2 text-sm text-red-600" id="email-error">Please make it shorter</p>
        }
      </div>
    )
  }
  
  
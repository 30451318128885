import { InformationCircleIcon } from '@heroicons/react/20/solid'

export default function Alert({ title, subtitle }) {
  return (
    <div className="rounded-md border border-iron-200 p-4 dark:bg-iron-900 dark:border-iron-800">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-iron-600 dark:text-iron-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-iron-600 dark:text-iron-400">{title}</h3>
          <div className="mt-2 text-sm text-iron-600 dark:text-iron-500">
            <p>{subtitle}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import LogoLight from '../assets/logo-light.svg';
import LogoDark from '../assets/logo-dark.svg';
import { classNames } from '../lib';


function LogoImage( { classes='' }) {
  return (
    <>
        <img
        className={classNames(`h-10 w-auto dark:hidden ${classes}`)}
        src={LogoLight}
        alt="Company logo"
        />
        <img
        className={classNames(`h-10 w-auto hidden dark:block ${classes}`)}
        src={LogoDark}
        alt="Company logo"
        />
    </>
  )
}

export default LogoImage
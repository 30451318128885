import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export default function Modal({ text, url, modelOpen, setModelOpen }) {

  return (
    <Transition.Root show={modelOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setModelOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-iron-800 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-2 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-2 dark:bg-iron-900">
                <div>
                        <img
                            src={url}
                            alt='product.imageAlt'
                            className="h-full w-full object-cover object-center group-hover:opacity-75"
                        />
                    {/* <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg">
                        <img
                            src='https://media.discordapp.net/attachments/1079405098687537304/1084139451913089105/iamwillyj_ceramic_pink_goblet_with_white_flower_inside_and_gree_eb2e1ac3-0293-4451-b6b9-115066d32643.png?width=634&height=634'
                            alt='product.imageAlt'
                            className="h-full w-full object-cover object-center group-hover:opacity-75"
                        />
                    </div> */}
                    <p className="inline-block mt-2 text-base text-iron-500">{text}</p>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export const formatWhatsappNumber = (number) => {
    let phone = number.replace(/\D/g, '');
    
      // Remove '0' or '91' prefix, if present
      if (phone.startsWith('0')) {
        phone = phone.slice(1);
      } else if (phone.startsWith('91') && phone.length === 12)  {
        phone = phone.slice(2);
      }
    
      // If phone number is 10 digits long, add '91' prefix
      if (phone.length === 10) {
        phone = '91' + phone;
      }
    
      // If phone number is not 10 digits long, return false
      if (phone.length !== 12) {
        return false
      }
    
      return phone
    
}
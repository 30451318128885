import React from 'react';
// import Head from 'next/head'
import { Helmet } from 'react-helmet';

export default function SEOMetadata() {
    const { title, description, image } = { title: "Elves of bookmykiln: AI Pottery Visualizer", description: "Elves help you visualize your ideas by generating realistic pottery images based on your inputs, using state-of-the-art Artificial Intelligence.", image: "https://uploads-ssl.webflow.com/63f8656b60aa12624bc51e3d/6413eb0050fd805ec5d3c28f_elves-meta-image.png" };
  return (
    <Helmet>
        <title>{title}</title>
        <meta
            name="description"
            content={description}
        />
        <meta itemProp="name" content={title}/>
        <meta itemProp="description" content={description}/>
        <meta itemProp="image" content={image}/>

        <meta property="og:url" content={`https://elves.bookmykiln.com`}/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={description}/>
        <meta property="og:image" content={image}/>

        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content={title}/>
        <meta name="twitter:description" content={description}/>
        <meta name="twitter:image" content={image}/>
        <link rel="icon" href="/favicon.ico" />
        <div>
        </div>
    </Helmet>
  )
}

export const viewImages = async () => {
    const url = process.env.REACT_APP_HASURA_ENDPOINT;
  
    const query = `
        query ViewImages {
            prompt(where: {image_url: {_is_null: false}}, order_by: {created_at: desc_nulls_last}) {
                image_url
                inputs
            }
            }
    `;
  
    
  
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-hasura-admin-secret': process.env.REACT_APP_HASURA_ADMIN_SECRET,
        'x-hasura-role': 'artist'
      },
      body: JSON.stringify({
        query
      })
    };
  
    const response = await fetch(url, options);
    const data = await response.json();
  
    return {
      data: data.data
    };
  };
  
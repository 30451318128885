import { useEffect, useState } from "react";
import Modal from "../../components/Modal"
import LoadingSkeleton from "../../components/LoadingSkeleton"
import { classNames, trimText } from "../../lib";
import { viewImages } from "../../lib/viewImages";


export default function Showcase() {
    const [data, setData] = useState()
    const [modelOpen, setModelOpen] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null);
    const [view, setView] = useState('grid');
    const [loading, setLoading] = useState(true);

    /* fetch images from database using async viewImages function and load it to images 
    state on first load. While it loads, show loading skeleton */

    useEffect(() => {
        const displayData = async () => {
            const dataFromServer = await viewImages()
            setData(dataFromServer.data.prompt)
            setLoading(false)
        }

        displayData()
    }, [])

    
return (
    <div className="bg-white dark:bg-iron-900">
    <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 id="products-heading" className="sr-only">
        Images
        </h2>
        <div className="grid grid-cols-[1fr,auto] items-center mb-8">
        <div className="flex min-w-0">
            <h2 className="truncate text-2xl font-medium leading-7 text-white dark:text-iron-100">Showcase</h2>
        </div>
        <div className="ml-6 flex items-center sm:hidden">
            <div className="flex space-x-1 rounded-lg bg-slate-100 p-0.5 dark:bg-iron-800" aria-orientation="horizontal">
                <button onClick={()=> setView('grid')} className={classNames(view==='grid'? 'bg-white shadow dark:bg-iron-500': '', "flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm font-semibold lg:pr-3")} role="tab" type="button" aria-selected="true" tabIndex="0"  aria-controls="headlessui-tabs-panel-728">
                    <svg className="h-5 w-5 flex-none stroke-iron-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" />
                    </svg>
                </button>
                
                <button onClick={()=> setView('list')} className={classNames(view==='list'? 'bg-white shadow dark:bg-iron-500': '', "flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm font-semibold lg:pr-3")} role="tab" type="button" aria-selected="false" tabIndex="-1" controls="headlessui-tabs-panel-729">
                    <svg className="h-5 w-5 flex-none stroke-iron-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                    </svg>

                </button>
            </div>
        </div>
        </div>

        <div className={classNames(view==='grid' ? 
        "grid-cols-2":
        "grid-cols-1",
        "grid gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8" )}>

        {loading ?
            Array.from({ length: 6 }).map((_, index) => (
                <LoadingSkeleton key={index} />
            ))
            :
          data.map((image, id) => (
            <a key={id} className="group" onClick={()=> {
            setSelectedImage(image);
            setModelOpen(true);
            }}>
            <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg sm:aspect-w-1 sm:aspect-h-1">
                <img
                src={image.image_url}
                alt={image.inputs.form}
                className="h-full w-full object-cover object-center group-hover:opacity-75"
                />
            </div>
            {/* <div className="mt-4 flex items-center justify-between text-base font-medium text-gray-900 dark:text-gray-400">
                <h3>{image.name}</h3>
                <p>{image.price}</p>
            </div> */}
              <p className="inline-block mt-1 text-xs italic text-iron-500">
              {`
                ${image.inputs && image.inputs.form? `${trimText(image.inputs.form, 25)}` : ''}${image.inputs && image.inputs.color? `, ${trimText(image.inputs.color, 25)}` : ''}${image.inputs && image.inputs.surface? `, ${trimText(image.inputs.surface, 25)}` : ''}
              `}
              </p>
            </a>
        ))}
        </div>
        <Modal 
          text={selectedImage && `${selectedImage.inputs && selectedImage.inputs.form? `${selectedImage.inputs.form}` : ''}${selectedImage.inputs && selectedImage.inputs.color? `, ${selectedImage.inputs.color}` : ''}${selectedImage.inputs && selectedImage.inputs.surface? `, ${selectedImage.inputs.surface}` : ''}`}
          url={selectedImage && selectedImage.image_url} 
          modelOpen={modelOpen} 
          setModelOpen={setModelOpen}
          />
    </div>
    </div>
)
}


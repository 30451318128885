import React from 'react';
import { Popover } from '@headlessui/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

function PopoverIcon({ text }) {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button className="focus:outline-none">
            <QuestionMarkCircleIcon className="h-5 w-5 text-iron-500" />
          </Popover.Button>
          {open && (
            <Popover.Panel 
                className="absolute z-10 bg-white border rounded-lg shadow-lg p-4 dark:bg-iron-800 dark:border-iron-700"
              style={{
                right: '0',
                top: 'calc(100% + 0.1rem)',
                minWidth: '12rem'
              }}
                
            >
              <p className="text-iron-700 dark:text-white">{text}</p>
            </Popover.Panel>
          )}
        </>
      )}
    </Popover>
  );
}

export default PopoverIcon;

export const checkArtist = async (whatsapp_number='919999999997') => {
    const url = process.env.REACT_APP_HASURA_ENDPOINT;
  
    const query = `
      query CheckArtist($_eq: String!) {
        artist(where: {whatsapp_number: {_eq: $_eq}}) {
          id
          whatsapp_number
        }
      }
    `;
  
    const _eq = whatsapp_number;
    const variables = {
      _eq,
    };
  
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-hasura-admin-secret': process.env.REACT_APP_HASURA_ADMIN_SECRET,
        'x-hasura-role': 'artist'
      },
      body: JSON.stringify({
        query,
        variables
      })
    };
  
    const response = await fetch(url, options);
    const data = await response.json();
  
    return {
      found: data.data.artist && data.data.artist.length > 0,
      artist_id: data.data.artist && data.data.artist.length > 0 ? data.data.artist[0].id : null
    };
  };
  
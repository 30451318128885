import Input from '../../components/Input';
import Button from '../../components/Button';
import Alert from '../../components/Alert';
import Notification from '../../components/Notification';
import { useState } from 'react';
import { addPrompt, addArtist, checkArtist, formatWhatsappNumber } from '../../lib';
import Toggle from '../../components/Toggle';

export default function PromptMaker() {
    const [isWhatsappChecked, setIsWhatsappChecked] = useState(true);
    const [inputs, setInputs] = useState({'form':"", 'color':"", 'surface':"", 'number':""});
    const [isLoading, setIsLoading] = useState(false);
    const [isNotificationShown, setIsNotificationShown] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState({'title': "", 'subtitle': ""});
    const maxInputLength = 40;
    const notificationDuration = 3000;
    

    const handleSubmit = async (e) => {
        setIsLoading(true);
        

        // create a function that checks the inputs and returns a boolean
        const checkInputs = () => {
            let allInputsAreValid = true;
            for (const input in inputs) {
                if (inputs[input].length > maxInputLength) {
                    allInputsAreValid = false;
                }
            }

            return allInputsAreValid && !(inputs.form.length===0 && inputs.color.length===0 && inputs.surface.length===0);
        }

        if (checkInputs()) {
            // console.log('checkInputs are valid')
            let artist_id = 1;


            // if isWhatsappChecked and number format is valid, create a function that checks if the number is already in the database
            if (isWhatsappChecked && !!formatWhatsappNumber(inputs.number)) {

                // console.log('whatsapp checked and whatsappnumber format is valid')
                let number = formatWhatsappNumber(inputs.number);
                const artistResult = await checkArtist(number);
                if (artistResult.found) {
                    // console.log('artist found')
                    // if the number is in the database, then addPrompt to the database and link to the artist_id
                    artist_id = artistResult.artist_id;
                } else {
                    // console.log('artist not found')
                    // if the number is not in the database, then addArtist to the database
                    const addArtistResult = await addArtist(number);
                    if (addArtistResult.added) {
                        // console.log('artist added successfully')
                        // if the number is not in the database and addArtist succeeds, then addPrompt to the database and link to the artist_id
                        artist_id = addArtistResult.artist_id;
                    } else {
                        // console.log('artist not added successfully')
                        // if the number is not in the database and addArtist fails, then I have to tell user to try again. I shouldn't addPrompt to the database
                        // show 'Try again. Something went wrong when we tried to add you' notification to the user
                        setNotificationMessage({'title': "Try again", 'subtitle': "Something went wrong when we tried to add you"});
                        setIsNotificationShown(true);
                        setTimeout(() => {
                            setIsNotificationShown(false);
                        }, notificationDuration);
                        setIsLoading(false);
                        return;
                    }

            }    
        } else if (isWhatsappChecked && !formatWhatsappNumber(inputs.number)) {
            // console.log('whatsappnumber format is invalid')
            // if number format is invalid, then I have to tell user to try again. I shouldn't addPrompt to the database
            // show 'Chekc you phone. Something went wrong' notification to the user
            setNotificationMessage({'title': "Check your number format", 'subtitle': "Something about your number is off. Please check it and try again"});
            setIsNotificationShown(true);
            setTimeout(() => {
                setIsNotificationShown(false);
            }, notificationDuration);
            setIsLoading(false);
            return;
        }
        // console.log('whatsapp not checked')
        // if not isWhatsappChecked or number format is invalid, then addPrompt to the database and link to the artist_id 1
        // remove number from inputs
        let {['number']: number, ...formattedInputs} = inputs;
        // remove empty space from the ends of formattedInputs
        for (const input in formattedInputs) {
            formattedInputs[input] = formattedInputs[input].trim();
        }
        // addPrompt to the database and link to the artist_id
        const addPromptResult = await addPrompt(artist_id, formattedInputs);
        // if addPrompt succeeds, then show 'Your wish is sent. Our elves will start working their magic now' notification to the user
        if (addPromptResult) {
            // console.log('prompt added successfully')
            setNotificationMessage({'title': "Your wish is sent", 'subtitle': "Our elves will start working their magic now"});
            setIsNotificationShown(true);
            setTimeout(() => {
                setIsNotificationShown(false);
            }, notificationDuration);

        } else {
            // console.log('prompt not added successfully')
            setNotificationMessage({'title': "Try again", 'subtitle': "Something went wrong when we tried to add your wish"});
            setIsNotificationShown(true);
            setTimeout(() => {
                setIsNotificationShown(false);
            }, notificationDuration);
        }

        // clear out all inputs except number
        setInputs({'form':"", 'color':"", 'surface':"", 'number':inputs.number});
    }

    setIsLoading(false);
}

  return (
    
          <main className="flex-1 bg-white dark:bg-iron-900">
            <div className="py-6">
              <div className="mx-auto my-16 px-4 sm:px-6 lg:px-8 md:max-w-xl text-center">
                <h1 className="text-4xl font-semibold text-iron-900 dark:text-white">Type your pottery idea</h1>
              </div>
              <form>

                <div className="mx-auto mb-12 px-4 sm:px-6 lg:px-8 md:max-w-xl">
                    <Input about='form' inputs={inputs} setInputs={setInputs} label="Form of the piece" placeholder='E.g, Ancient vase, bowl, Arabic plate' popoverText="What type of pottery form do you want to generate from the elves?" maxInputLength={maxInputLength} />
                </div>

                <div className="mx-auto mb-12 px-4 sm:px-6 lg:px-8 md:max-w-xl">
                    <Input about='color' inputs={inputs} setInputs={setInputs} label="Color of the piece" placeholder='E.g, Lilac, dark magenta, brownish green' popoverText="Choose the dominant color of the piece." maxInputLength={maxInputLength} />
                </div>

                <div className="mx-auto mb-12 px-4 sm:px-6 lg:px-8 md:max-w-xl">
                    <Input about='surface' inputs={inputs} setInputs={setInputs} label="Surface details" placeholder='E.g, red strokes, peacock details, giraffe on lid' popoverText="Choose how the surface should appear" maxInputLength={maxInputLength} />
                </div>

                {/* <div className='mx-auto mb-12 px-4 sm:px-6 lg:px-8 md:max-w-xl'>
                    <div className="flex items-center">
                        <input
                            id="remember-me"
                            name="remember-me"
                            type="checkbox"
                            className="h-4 w-4 rounded text-blue-600 focus:ring-0"
                            checked={isWhatsappChecked}
                            onChange={(e) => setIsWhatsappChecked(e.target.checked)}
                        />
                        <label htmlFor="remember-me" className="ml-2 block text-sm text-iron-900 dark:text-white">
                            Send me image on WhatsApp
                        </label>
                    </div>
                </div> */}

                <div className='mx-auto mb-12 px-4 sm:px-6 lg:px-8 md:max-w-xl'>
                    <div className="flex items-center">
                        <Toggle status={isWhatsappChecked} statusChange={()=> setIsWhatsappChecked(!isWhatsappChecked)} />
                        <label htmlFor="remember-me" className="ml-2 block text-sm text-iron-900 dark:text-white">
                            Send me image on WhatsApp
                        </label>
                    </div>
                </div>

                { isWhatsappChecked &&
                    <div className="mx-auto mb-12 px-4 sm:px-6 lg:px-8 md:max-w-xl">
                    <Input about='number' inputs={inputs} setInputs={setInputs} type='tel' label="Whatsapp Number" placeholder='E.g, +91 98765 54321' popoverText="We'll send you the image via whatsapp" maxInputLength={maxInputLength} />
                </div>}

                <div className="mx-auto mb-12 px-4 sm:px-6 lg:px-8 md:max-w-xl">
                    <Button type='button' onClick={(e)=> handleSubmit(e)} disabled={isLoading} isLoading={isLoading}/>
                    <div className='text-center pb-10'>
                        {/* <p className="my-2 text-xs text-iron-900 dark:text-white">20 credits available</p> */}
                    </div>
                    <Alert title='How long will it take?' subtitle='A few minutes. Sometimes an hour or so. If you submit more than 20, yours might take longer. AI is kinda expensive and we want everyone to try :)'/>
                </div>

              </form>
              <Notification title={notificationMessage.title} subtitle={notificationMessage.subtitle} isNotificationShown={isNotificationShown} setIsNotificationShown={setIsNotificationShown}/>
            </div>
          </main>

  )
}

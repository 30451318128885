import React, { useState, useEffect } from 'react';
import Layout from './components/Layout';
import { PromptMaker, Showcase } from './screens';

function App() {
  const [theme, setTheme] = useState(
    /* window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light' */ /* looks at user's default theme */
    'dark'
  );

  const [screen, setScreen] = useState('Make');

  useEffect(() => {
    document.documentElement.classList.toggle('dark', theme === 'dark');
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    setTheme(newTheme);
  };

  return (
    <Layout theme={theme} toggleTheme={toggleTheme} screen={screen} setScreen={setScreen}>
      {screen !== 'Make' ? null : <PromptMaker/> }
      {screen !== 'Showcase' ? null : <Showcase/> }
    </Layout>
  );
}

export default App;


/* 

<div className="bg-white dark:bg-iron-900 p-4">
        <h1 className="text-2xl font-bold text-iron-900 dark:text-iron-100">
          Hello, world!
        </h1>
        <p className="text-iron-700 dark:text-iron-300">
          This is a simple ReactJS web app with TailwindCSS.
        </p>
        <button
          className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={toggleTheme}
        >
          {theme === 'dark' ? 'Switch to light mode' : 'Switch to dark mode'}
        </button>
      </div>

*/